<div class="container-fluid">
  <app-pagetitle title="About Deletion" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <h4 class="card-header mt-0">Deleting your account</h4>
        <div class="card-body">
         
          <p class="card-text">We want to make it easy to delete your account, simply login using the above link, and click the large "DELETE ACCOUNT" button on the main dashboard screen.</p>
          <p class="card-text">Please make sure your cancel any payment instructions first!</p>
          


        </div>
      </div>
    </div>


</div>