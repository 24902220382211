import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})

/**
 * About Component
 */
export class DeleteComponent implements OnInit {

 
  constructor() {
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Locksley' }, { label: 'Account Deletion', active: true }];
  }
}
