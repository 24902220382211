import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlMatchResult } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsComponent } from './products/products.component';
import { AppComponent } from './app/app.component';
import { LegalComponent } from './legal/legal.component';
import { StripeComponent } from './stripe/stripe.component';
import { PricingComponent } from './pricing/pricing.component';

import { PartnersComponent } from './partners/partners.component';
import { DeleteComponent } from './delete/delete.component';
 


export function customMatcher(url: UrlSegment[]): UrlMatchResult | null {
    const validPaths = ['ALS', 'LTM', 'LTS', 'LLT', 'JJH', 'SLT', 'FLT', 'LL1','LOCKEXPO']; // Add your valid paths here
    if (url.length === 1 && validPaths.includes(url[0].path.toUpperCase())) {
      return {
        consumed: url,
        posParams: {
          id: new UrlSegment(url[0].path, {})
        }
      };
    }
    return null;
  }


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'about', component: AboutComponent},
    { path: 'delete', component: DeleteComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'products', component: ProductsComponent},
    { path: 'app', component: AppComponent},
    { path: 'legal', component: LegalComponent},
    { path: 'stripe', component: StripeComponent},
    { path: 'pricing', component: PricingComponent},

    { matcher: customMatcher, component: PartnersComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
